// Example
// module.exports = {
//   AssetMark: {
//     qa: [
//       {
//         text: 'client-001',
//         value: {
//           username: 'client-001.xxx@better-debt.com',
//           password: 'aUlZ9i6!',
//           mfa: [
//             { question: 'In what year was your father born?', answer: '1111' },
//             {
//               question: 'Who is your favorite basketball player?',
//               answer: '2222',
//             },
//             { question: 'What was your childhood nickname?', answer: '3333' },
//           ],
//         },
//       },
//     ],
//   },
// }
module.exports = {}
