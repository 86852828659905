function focusElementById(elementId) {
  if (!!elementId) {
    const element = document.getElementById(elementId)
    element && element.focus()
    return true
  }
  return false
}

export { focusElementById }
