import {
  GroupTypeEnum,
  ModulePathsEnum,
  ResultToggleMoreLength,
  RiskModeEnum,
  RoleTypeEnum,
  AuthEnum,
} from './global-search.enum'

const lineListPath = '/service/line-list'
const drawRequestPath = '/service/action-center/draw-request-list'
const riskEventPath = '/service/line/collateral-call'
const lineCollateralPath = '/service/line/collateral-accounts'
const collateralReleasePath = '/service/action-center/collateral-release'

export default {
  data() {
    return {
      isCallMode: true,
    }
  },
  computed: {
    roleMenuList() {
      return this.$store.state?.userInfo?.roleConfiguration?.menuList ?? []
    },
    isInvestment() {
      const roleType = this.$store.state.userInfo?.profile?.roleType
      return this.$utils.isEqualStringIgnoreCase(
        RoleTypeEnum.investment,
        roleType
      )
    },
    serviceSearchGroups() {
      return [
        {
          groupName: this.$ts('LINE'),
          typeCode: GroupTypeEnum.line,
          requiredMenu: lineListPath,
          normalizeResult: this.serviceNormalizeResult,
          getResultContents: this.getLineResultContents,
          modulePaths: ModulePathsEnum.service,
        },
        {
          groupName: this.$ts('DRAW_REQUEST'),
          typeCode: GroupTypeEnum.drawRequest,
          requiredMenu: drawRequestPath,
          normalizeResult: this.serviceNormalizeResult,
          getResultContents: this.getDrawRequestResultContents,
          modulePaths: ModulePathsEnum.service,
        },
        {
          groupName: this.isCallMode
            ? this.$ts('COLLATERAL_CALL')
            : this.$ts('COLLATERAL_SHORTFALL'),
          typeCode: GroupTypeEnum.collateralCall,
          requiredMenu: lineCollateralPath,
          normalizeResult: this.serviceNormalizeResult,
          getResultContents: this.getRiskEventResultContents,
          modulePaths: ModulePathsEnum.service,
        },
        {
          groupName: this.$ts('COLLATERAL_RELEASE_REQUEST'),
          typeCode: GroupTypeEnum.collateralRelease,
          requiredMenu: collateralReleasePath,
          normalizeResult: this.serviceNormalizeResult,
          getResultContents: this.getCollateralReleaseResultContents,
          modulePaths: ModulePathsEnum.service,
        },
      ].filter((searchType) => {
        return this.roleMenuList.some(
          (menu) => menu.path === searchType.requiredMenu
        )
      })
    },
    needSearchRiskEvent() {
      return this.searchTypes.includes(GroupTypeEnum.collateralCall)
    },
  },
  created() {
    if (this.needSearchRiskEvent) {
      this.getCMConfig()
    }
  },
  methods: {
    getCMConfig() {
      this.$http.get('/v5/cm/config/general').then((res) => {
        const riskEventMode = res?.data?.riskEventMode || RiskModeEnum.call
        this.isCallMode = this.$utils.isEqualStringIgnoreCase(
          riskEventMode,
          RiskModeEnum.call
        )
      })
    },
    getLineContentPath(locId) {
      const peopleEntitiesPath = '/service/line/people-and-entities'
      const contentNeedAuth = this.roleMenuList.some(
        (menu) => menu.path === peopleEntitiesPath
      )
      return contentNeedAuth ? `${peopleEntitiesPath}?locId=${locId}` : null
    },
    normalizeLineParties(result, contentRouter) {
      const parties = result?.partiesList ?? []
      return parties.map((item) => {
        let roles = item?.roleList ?? []
        roles = this.$utils.format.text(roles.join(', '))
        const partyName = this.$utils.format.text(item?.partyName)
        const loanNumber = this.$utils.format.text(item?.loanNumber)
        return {
          contentText: `${partyName} (${roles}) | ${loanNumber}`,
          contentRouter,
        }
      })
    },
    normalizeLineAdvisors(result, contentRouter) {
      const advisors = result?.advisorList ?? []
      return advisors.map((item) => {
        const advisorName = this.$utils.format.text(item?.advisorName)
        const advisorType = this.$utils.format.text(
          item?.advisorType ?? this.$ts('advisor')
        )
        return {
          contentText: `${advisorName} (${advisorType})`,
          contentRouter,
        }
      })
    },
    getLineResultContents(result) {
      const assets = result?.collateralAccountNumberList ?? []
      const showMoreAssets =
        assets.length > ResultToggleMoreLength.lineAssets ? false : null

      const contentRouter = this.getLineContentPath(result?.locId)
      const parties = this.normalizeLineParties(result, contentRouter)
      const advisors = this.normalizeLineAdvisors(result, contentRouter)
      const resultContents = this.$lodash.concat(parties, advisors)

      const showMoreContent =
        resultContents.length > ResultToggleMoreLength.linePerson ? false : null

      const lineDetailPath = this.isInvestment
        ? '/service/activity-center/my-line'
        : '/service/line-detail'
      const resultRouter = `${lineDetailPath}?locId=${result?.locId}`

      return {
        assets,
        resultContents,
        resultRouter,
        showMoreAssets,
        showMoreContent,
      }
    },
    getDrawRequestResultContents(result) {
      const drawRequestsList = result?.drawRequestsList ?? []

      const resultContents = drawRequestsList.map((drawRequest) => {
        const drawRequestedBy = this.$utils.format.text(
          drawRequest?.drawRequestedBy
        )
        const drawRequestAmount = this.$utils.format.currency(
          drawRequest?.drawRequestAmount
        )
        return {
          contentText: `${drawRequestedBy} | ${this.$t(
            'pending_request'
          )}: ${drawRequestAmount}`,
        }
      })
      const resultRouter = `${drawRequestPath}?searchKey=${result?.lineNumber}`
      return {
        resultRouter,
        resultContents,
      }
    },

    getCollateralReleaseResultContents(result) {
      const collateralReleaseList = result?.collateralReleaseRequestsList ?? []
      const resultContents = collateralReleaseList.map((collateralRelease) => {
        const primaryContact = this.$utils.format.text(
          collateralRelease?.primaryContact
        )
        const releaseAmount = this.$utils.format.currency(
          collateralRelease?.releaseAmount
        )
        const releaseDetailPath =
          '/service/action-center/collateral-release/collateral-release-detail'
        return {
          contentText: `${primaryContact} | ${this.$t(
            'pending_request'
          )}: ${releaseAmount}`,
          contentRouter: `${releaseDetailPath}?locId=${result?.locId}&collateralReleaseId=${collateralRelease?.collateralReleaseId}`,
        }
      })

      const resultRouter = `${collateralReleasePath}?searchKey=${result?.lineNumber}`
      return {
        resultRouter,
        resultContents,
      }
    },
    getRiskEventResultContents(result) {
      const riskEventList = result?.riskEventList ?? []
      const locId = result?.locId ?? ''

      const collateralRiskEventPath = this.isInvestment
        ? lineCollateralPath
        : riskEventPath

      const resultContents = riskEventList.map((riskEvent) => {
        const borrowerNames = this.$utils.format.text(riskEvent?.borrowerNames)
        const eventNumber = this.$utils.format.text(riskEvent?.eventNumber)
        const eventValue = this.$utils.format.currency(riskEvent?.eventValue)
        const riskEventId = riskEvent?.riskEventId ?? ''

        const serviceRiskDetailAuth = this.hasAuthed(
          'viewCallDetail',
          AuthEnum.open,
          'CLIENT_SERVICE_COLLATERAL_CALL'
        )
        const advisorDetailAuth = this.hasAuthed(
          'viewCallDetail',
          AuthEnum.open,
          'ADVISOR_COLLATERAL'
        )

        const detailNeedAuth = serviceRiskDetailAuth || advisorDetailAuth
        const contentRouter = detailNeedAuth
          ? `${collateralRiskEventPath}?locId=${locId}&riskEventId=${riskEventId}`
          : null
        return {
          contentText: `${borrowerNames} | ${eventNumber} | ${eventValue}`,
          contentRouter,
        }
      })

      const resultRouter = `${collateralRiskEventPath}?locId=${locId}`
      return {
        resultContents,
        resultRouter,
      }
    },
    serviceNormalizeResult(result, getResultContents) {
      const locNumber = this.$utils.format.text(result?.lineNumber)
      const lineName = this.$utils.format.text(result?.lineName)
      const resultTitle = `${lineName} (${locNumber})`

      return {
        resultTitle,
        ...getResultContents(result),
      }
    },
  },
}
