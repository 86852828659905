export default {
  setShowMFA(state, data) {
    state.IS_SHOW_MFA = data
  },
  setShowGlobalSearch(state, data) {
    state.IS_SHOW_GLOBAL_SEARCH = data
  },
  addSearchHistory(state, data) {
    const maxSearchHistorySize = 5
    const historyIndex = state.SEARCH_HISTORY.findIndex((item) => item === data)
    if (historyIndex !== -1) {
      state.SEARCH_HISTORY.splice(historyIndex, 1)
    }
    if (state.SEARCH_HISTORY.length > maxSearchHistorySize - 1) {
      state.SEARCH_HISTORY.length = maxSearchHistorySize - 1
    }
    state.SEARCH_HISTORY.unshift(data)
    localStorage.setItem('SEARCH_HISTORY', JSON.stringify(state.SEARCH_HISTORY))
  },
  deleteSearchHistory(state, data) {
    const historyIndex = state.SEARCH_HISTORY.findIndex((item) => item === data)
    if (historyIndex !== -1) {
      state.SEARCH_HISTORY.splice(historyIndex, 1)
    }
    localStorage.setItem('SEARCH_HISTORY', JSON.stringify(state.SEARCH_HISTORY))
  },
  setMFAPhoneNumber(state, data) {
    sessionStorage.setItem('MFA_PHONE_NUMBER', JSON.stringify(data))
    state.MFA_PHONE_NUMBER = data
  },
  setFrontendConfig(state, data) {
    state.frontendConfig = data
  },
  setHttpHeaders(state, data) {
    state.httpHeaders = data
  },
  setWebsiteCode(state, data) {
    state.websiteCode = data
  },
  setUserInfo(state, data) {
    state.userInfo = data
  },
  setWhitelistUrl(state, data) {
    state.whitelistUrl = data
  },
  setToggleSideBarStatus(state, data) {
    state.toggleSideBarStatus = data
  },
  setSidebarData(state, data) {
    state.sidebarData = data
  },
  setFullScreenLoading(state, data) {
    state.fullScreenLoading = data
  },
  setMenuHandCollapseSwitch(state, data) {
    state.menuHandCollapseSwitch = data
  },
  changeSidebarStatus(state) {
    state.sidebarExpand = !state.sidebarExpand
  },
  setCodePermissions(state, data) {
    state.codePermissions = data
  },
  setMobileMenuStatus(state, data) {
    state.mobileMenuStatus = data
  },
  setSysMobilStatus(state, data) {
    state.isMobile = data
  },
  setTwoHundredsTimesZoom(state, data) {
    state.twoHundredsTimesZoom = data
  },
  setLogoutBecauseOfSingleDeviceRule(state, data) {
    state.logoutBecauseOfSingleDeviceRule = data
  },
  setNeedSaveIpToServer(state, data) {
    state.needSaveIpToServer = data
  },
}
