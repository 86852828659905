<script>
export default {
  render() {
    const icon = this.getRenderIcon()
    const menuItem = (item) => {
      return (
        <s-menu-item
          id={this.$generateId('menu', item.menuCode)}
          index={this.getKey(item.path)}
          key={item.label}
          page-name={item.label}
          style={`${item.visibility ? '' : 'display: none;'} ${
            this.siderWidth ? 'opacity:0;' : 'opacity:1;'
          }`}
          nativeOnClick={($event) => {
            this.goto($event, item)
          }}
          on-keydown={($event) => {
            this.goto($event, item)
          }}
          disabled={item.disabled}
        >
          {icon(item)}
          <span>{item.label}</span>
        </s-menu-item>
      )
    }

    const subMenu = (item) => {
      return (
        <s-sub-menu
          id={this.$generateId('submenu', item.menuCode)}
          index={item.path}
          key={item.label}
          style={item.visibility ? '' : 'display: none;'}
          nativeOnClick={($event) => {
            this.goto($event, item, true)
          }}
          on-keydown={($event) => {
            this.goto($event, item, true)
          }}
          disabled={item.disabled}
          title-link={item.titleLink}
        >
          <template slot="title">
            {icon(item)}
            <span>{item.label}</span>
          </template>
          <s-menu-item-group>
            {subMenuOrMenuItem(item.children)}
          </s-menu-item-group>
        </s-sub-menu>
      )
    }

    const subMenuOrMenuItem = (data) => {
      return data.map((item) => {
        return item.children ? subMenu(item) : menuItem(item)
      })
    }

    const style = this.siderWidth ? 'width: 0' : ''
    return this.sidebarData.length > 0 ? (
      <div
        class="sidebar-wrap test-color flex-grow-0 flex-shrink-0"
        style={style}
      >
        <s-menu
          handSwitch={this.handSwitch}
          inlineCollapsed={this.$store.getters.isShowMobileMenu}
          class="sidebar-menu"
          onChangeCollapseStatus={this.changeCollapseStatus}
          style={`height: calc(100vh - ${this.headerNavbarHeight}px); top: ${this.headerNavbarHeight}px;`}
          selectedIndex={this.selectedIndex}
        >
          {subMenuOrMenuItem(this.sidebarData)}
        </s-menu>
      </div>
    ) : null
  },
  props: {
    headerNavbarHeight: {
      type: Number,
      default: 56,
    },
  },
  data() {
    return {
      selectedIndex: '',
      siderWidth: null,
    }
  },
  computed: {
    sidebarData() {
      return this.$store.state.sidebarData || []
    },
    handSwitch() {
      return (
        this.$store.getters.getMenuHandCollapseSwitch ||
        this.$store.getters.isShowMobileMenu
      )
    },
    inheritQuery() {
      const inheritQueryKeys = ['locId']
      const inheritQuery = {}
      const currentQuery = this.$route.query
      for (const key of Object.keys(currentQuery)) {
        if (inheritQueryKeys.includes(key)) {
          inheritQuery[key] = currentQuery[key]
        }
      }
      return inheritQuery
    },
  },
  watch: {
    $route() {
      this.init()
    },
    sidebarData(oldV, v) {
      if (v && v.length === 0) this.selectedIndex = ''
      if (oldV.length === 0 && v && v.length) {
        this.$nextTick(() => {
          this.siderWidth = false
        })
      }
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      if (!this.sidebarData.some((item) => item.path === this.$route.path)) {
        this.$store.commit(
          'setSidebarData',
          this.$store.getters.sidebarData(this.$route)
        )
      }
    },
    getRenderPath(item) {
      return item.icon.path ? <path d={item.icon.path}></path> : null
    },
    getRenderIcon() {
      const vm = this
      return (item) => {
        if (item.icon) {
          const name = item.icon.path ? undefined : item.icon.name
          return (
            <s-icon
              icon-name={name}
              icon-color={item.icon.color || '#60759A'}
              width={item.icon.width || 24}
              height={item.icon.height || 24}
            >
              {vm.getRenderPath(item)}
            </s-icon>
          )
        }
        return null
      }
    },
    goto(e, item, isSubMenu = false) {
      e.stopPropagation && e.stopPropagation()
      if (
        (isSubMenu && item?.titleLink !== true) ||
        this.$utils.isEmpty(item.path) ||
        this.$route.fullPath === item.path ||
        this.$route.path === item.path
      ) {
        return
      }
      if (item.externalLink) {
        window.open(item.path)
        return
      }
      this.$router.push({
        path: item.path,
        query: {
          ...this.inheritQuery,
          ...item.query,
        },
      })
      this.$store.commit('setToggleSideBarStatus', false)
    },
    getKey(path) {
      if (path && this.$route.fullPath.indexOf(path) > -1) {
        this.$nextTick(() => {
          this.selectedIndex = path
        })
      }
      return path
    },
    changeCollapseStatus(value) {
      if (!this.handSwitch) {
        return
      }
      this.$store.commit('changeSidebarStatus')
      this.siderWidth = value
    },
  },
}
</script>
<style lang="scss" scoped>
.sidebar-wrap {
  background-color: $white;
  width: 255px;
  :deep(.s-menu) {
    background: #fff;
    &.s-menu-item {
      transition: opacity 0.3s;
    }
  }
  .sidebar-menu {
    position: fixed;
    z-index: $zindex-sticky;
  }
}
</style>
