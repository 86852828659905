import blankLayout from '@/layouts/blankLayout'
import authLayout from '@/layouts/authLayout'
import basicLayout from '@sn/framework/src/views/Layout'
import portalBasicPages from './portalBasicPages'
import portalErrorPages from './portalErrorPages'
import termsPages from './termsPages'
import settingPages from './settingPages'

// set white url list
window.sessionStorage.setItem(
  'whitelistUrl',
  JSON.stringify([
    ...portalBasicPages.map((val) => val.path),
    ...portalErrorPages.map((val) => val.path),
  ])
)

export default [
  // portal basic pages
  {
    path: '/auth',
    name: 'layout',
    component: authLayout,
    children: [...portalBasicPages],
  },
  // portal error pages
  {
    path: '/error-page',
    name: 'error-page',
    component: blankLayout,
    children: [...portalErrorPages],
  },
  // terms
  {
    path: '/terms',
    name: 'terms',
    component: authLayout,
    children: [...termsPages],
  },
  // help
  {
    path: '/help',
    name: 'help',
    component: basicLayout,
    children: [
      {
        path: '/help',
        name: 'help',
        meta: {
          title: 'Help',
        },
        component: () =>
          import(
            /* webpackChunkName: "help-page" */ '@/views/common/help/help-center.vue'
          ),
      },
      {
        path: '/contact-us',
        name: 'contact-us',
        meta: {
          title: 'Contact Us',
        },
        component: () =>
          import(
            /* webpackChunkName: "help-page" */ '@/views/common/help/contact-us.vue'
          ),
      },
    ],
  },
  // settings
  {
    path: '/settings',
    name: 'settings',
    component: basicLayout,
    children: [...settingPages],
  },
]
