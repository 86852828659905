import * as plugins from './src/plugins'
import Layout from './src/views/Layout.vue'
import App from './src/App.vue'
import { Entry } from './src/core/Entry'

function createEntry({ global = window, layout, app } = {}) {
  layout = layout || Layout
  app = app || App
  const vue = plugins.Vue
  const progress = plugins.Vue.prototype.$progress
  const router = plugins.router
  const store = plugins.store
  const i18n = plugins.i18n

  return new Entry({
    global,
    layout,
    app,
    vue,
    router,
    store,
    i18n,
    progress,
  })
}
export default createEntry
