import _ from 'lodash'

/**
 * @description turns params into kebabCased id
 * @param {String} prefix optional
 * @param {String} elementName
 * @example generateId('menu', menuCode) or generateId('moduleFunctionName_elementName')
 * @returns {String} menu_cm-people-entities or module-function-name_element-name
 */
export default function generateId(...args) {
  if(_.isEmpty(args)) {
    return undefined
  }
  return args.map(i => _.kebabCase(i)).join('_')
}