const termsPages = [
  {
    path: '/login-terms',
    name: 'login-term-page',
    meta: {
      darkBackground: true,
      title: 'Login Term',
    },
    component: () =>
      import(
        /* webpackChunkName: "login-term-page" */ '@/views/terms/index.vue'
      ),
  },
]

export default termsPages
