<template>
  <div>
    <div>
      <div class="d-flex justify-content-center mt-48">
        <router-link class="navbar-brand-content" to="/auth/login" v-if="logo">
          <img id="navbar-logo" ref="logoImg" :src="logo" :alt="logoAlt" />
        </router-link>
      </div>
      <div class="d-flex justify-content-center">
        <div class="branding-wrap d-flex align-items-center">
          <h2
            class="action-title theme-text-500 text-center fs-18"
            v-if="settings.companyTitle"
            v-html="sanitizeCompanyTitle"
          ></h2>
          <div
            class="branding-logo-wrap ml-8"
            v-if="!$utils.isEmpty(brandingImg)"
          >
            <img :src="brandingImg" :alt="logoAlt" />
          </div>
        </div>
      </div>
    </div>
    <router-view />
    <footer-content class="mt-24" type="login" :fluid="fluid"></footer-content>
  </div>
</template>

<script>
import footerContent from '@sn/framework/src/views/components/footer'

export default {
  name: 'AuthLayout',
  components: {
    footerContent,
  },
  computed: {
    settings() {
      return this.$store.state.frontendConfig.settings || {}
    },
    logo() {
      return this.settings.images?.logo || ''
    },
    logoAlt() {
      if (this.settings.i18n && this.settings.i18n.website_logo_alt) {
        return this.settings.i18n.website_logo_alt
      }
      return this.settings.companyTitle || 'logo'
    },
    sanitizeCompanyTitle() {
      return this.$utils.sanitizeHtml(this.settings.companyTitle || '')
    },
    brandingImg() {
      const { websiteBrandingPageLogo = '' } = this.settings?.images || {}
      const lastKey = websiteBrandingPageLogo.lastIndexOf('/')
      const imgLn =
        lastKey > 0 ? websiteBrandingPageLogo.substring(lastKey + 1).length : 0
      return imgLn ? websiteBrandingPageLogo : ''
    },
    fluid() {
      return (
        this.$store?.state?.frontendConfig?.settings?.loginPageFuildLayout ??
        false
      )
    },
  },
}
</script>
<style lang="scss" scoped>
.branding-wrap {
  .branding-logo-wrap {
    width: 100px;
    height: 20px;
    position: relative;
    img {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
