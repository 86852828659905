import {
  downloadWithUrl,
  downloadWithData,
  previewWithData,
} from './file-download'
import { Base64 } from 'js-base64'
import BigNumber from 'bignumber.js'
import numeral from 'numeral'

export function base64Encode(value) {
  return Base64.encode(value)
}
/**
 * @deprecated please use base64Decode instead
 * @param {*} value
 * @returns
 */
export function Base64Decode(value) {
  return Base64.decode(value)
}
export function base64Decode(value) {
  return Base64.decode(value)
}
export function downloadFile(data, name) {
  return downloadWithData(data, name)
}
export function downloadFileWithUrl(url, name) {
  return downloadWithUrl(url, name)
}
export function previewFile(data, name) {
  return previewWithData(data, name)
}
export function bigNumber(value) {
  return BigNumber(value)
}
export function toNumber(value) {
  return numeral(value)
}
