function _getParentTopMenuId(list, route, parentId) {
  const includesPath = (path, childrenPaths) => {
    const paths =
      typeof childrenPaths === 'string' ? childrenPaths.split(',') : []
    return path === route.path || paths.includes(route.path)
  }
  const targetMenuItem = parentId
    ? list.filter((v) => v.menuId === parentId)[0]
    : list.filter((v) => includesPath(v.path, v.childPath))[0]
  if (!targetMenuItem) return ''

  switch (targetMenuItem.type) {
    case 'sideBar':
      if (targetMenuItem.menuId + '' !== '0') {
        return _getParentTopMenuId(list, route, targetMenuItem.parentMenuId)
      } else {
        console.warn(
          'This menu item type is [sideBar] but menuId is [0] =>' +
            targetMenuItem
        )
        return ''
      }

    case 'topMenu':
      return targetMenuItem.menuId

    default:
      console.warn('This menu item type is error!  =>' + targetMenuItem)
      return ''
  }
}

function _getTreeData(list, parentId, allSidebarItems) {
  return list
    .map((e) => {
      let result = false
      if (e.parentMenuId + '' === parentId + '' && e.type === 'sideBar') {
        const data = {
          label: e.displayName || e.name,
          key: e.menuId,
          type: e.type,
          displayOrder: e.displayOrder,
          parentMenuId: e.parentMenuId,
          path: e.path,
          menuCode: e.menuCode,
          titleLink: e?.titleLink || false,
          visibility: true,
        }
        const childrenData = _getTreeData(list, e.menuId, allSidebarItems)
        if (childrenData.length > 0) data.children = childrenData
        allSidebarItems.push(data.path)
        if (e.childPath) allSidebarItems.push(...e.childPath.split(','))
        result = data
      }
      return result
    })
    .filter((v) => v !== false)
    .sort(function (a, b) {
      return a.displayOrder * 1 - b.displayOrder * 1
    })
}

export default {
  role: (state) => {
    return state.userInfo.role || 'guest'
  },
  isShowMobileMenu: (state) => {
    return state.isMobile || state.twoHundredsTimesZoom
  },
  getMenuHandCollapseSwitch: (state) => {
    return state.menuHandCollapseSwitch || false
  },
  microAppNames: (state, _getters) => {
    const microApp = state.frontendConfig?.settings?.microApp || {}
    return Object.keys(microApp).map((key) => microApp[key].productName)
  },
  microAppBaseUrls: (state, _getters) => {
    const microApp = state.frontendConfig?.settings?.microApp || {}
    return Object.keys(microApp).map((key) => {
      return (microApp[key].basePath || '')
        .replace(/^\/*/, '')
        .replace(/\/*$/, '')
    })
  },
  codePermissions: (state, _getters) => (route) => {
    const pageCodeSet = new Set(route?.meta?.pageCode || [])
    route.matched?.forEach((r) => {
      r.meta?.pageCode?.forEach((c) => {
        pageCodeSet.add(c)
      })
    })

    const actionPermission =
      state.userInfo?.roleConfiguration?.actionPermission || {}
    let rel = {}
    pageCodeSet.forEach((code) => {
      if (actionPermission[code]) {
        rel = { ...(rel || {}), ...(actionPermission[code] || {}) }
      }
    })
    return rel
  },
  pagePermissions: (state, getters) => {
    const permissions = state.frontendConfig?.permission?.page || {}
    const role = getters.role
    return Object.entries(permissions).reduce((items, [name, config]) => {
      const listType = config.listType || 'white'
      const listData = config.listData || []

      if (listType === 'white') {
        if (listData.includes(role)) {
          items[name] = true
        }
      } else {
        if (!listData.includes(role)) {
          items[name] = true
        }
      }

      return items
    }, {})
  },
  sidebarData: (state, _getters) => (route) => {
    const menuList = state?.userInfo?.roleConfiguration?.menuList || []
    const parentTopMenuId = _getParentTopMenuId(menuList, route)

    const allSidebarItems = []
    const sideBarTreeData = parentTopMenuId
      ? _getTreeData(menuList, parentTopMenuId, allSidebarItems)
      : []
    return allSidebarItems.includes(route.path) ? sideBarTreeData : []
  },

  whitelistUrl(_state, _getters) {
    let result
    try {
      result = JSON.parse(window.sessionStorage.getItem('whitelistUrl')) || []
    } catch (error) {
      result = []
      console.error(error)
    }

    return result
  },
}
