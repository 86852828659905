<template>
  <s-advanced-table
    id="choose-profile_profile-table"
    :tableOptions="tableOptions"
    :summary="$t('switch_profile')"
  >
    <template v-slot:action="row">
      <s-button
        :id="`choose-profile_select-profile-${row.rowIndex}-button`"
        v-if="row.data.profileId !== currentProfileUid"
        size="sm"
        :disabled="isSubmitting"
        button-type="outline-primary"
        @click.native="selectProfile(row)"
        >{{ $t('operation.select') }}</s-button
      >
      <span v-else class="selected">{{ $t('current') }}</span>
    </template>
  </s-advanced-table>
</template>

<script>
export default {
  data() {
    return {
      isSubmitting: false,
      tableOptions: {
        loading: true,
        hasAction: true,
        height: '400',
        pagination: false,
        sortKey: {},
        columns: [
          {
            title: this.$t('profile.role_name'),
            dataKey: 'roleName',
            search: true,
          },
          {
            title: 'Profile Name',
            dataKey: 'profileName',
            search: true,
          },
          {
            title: 'Role Type',
            dataKey: 'roleType',
            search: true,
          },
        ],
        tableDataSource: [],
        search: {
          show: false,
          searchKeyValue: '',
          searchPlaceholder: 'Search',
        },
        totalCount: {
          show: false,
          count: '-',
        },
        filter: {
          show: false,
          filterList: [],
          filterTypeName: {
            value: 'value',
            date: 'date',
            type: 'type',
          },
        },
      },
    }
  },
  computed: {
    currentProfileUid() {
      return this.$store.state.userInfo?.profile?.profileId
    },
  },
  created() {
    this.loadTableData()
  },
  methods: {
    fetchProfiles() {
      return this.$http.get('/auth/profiles').then(({ data }) => {
        return data
      })
    },
    setLoginProfile(payload) {
      return this.$http
        .post('/auth/login-profile', payload)
        .then(({ data }) => {
          return data
        })
    },
    setCurrentProfile(payload) {
      return this.$http
        .post('/auth/current-profile', payload)
        .then(({ data }) => {
          return data
        })
    },
    loadTableData() {
      this.tableOptions.loading = true
      this.fetchProfiles()
        .then((data) => {
          this.tableOptions.totalCount.count = data.totalItems
          this.tableOptions.tableDataSource = data.items
        })
        .finally(() => {
          this.tableOptions.loading = false
        })
    },
    selectProfile(row) {
      const profileId = row.data.profileId
      let promise

      this.isSubmitting = true
      if (this.currentProfileUid) {
        promise = this.setCurrentProfile({ profileId })
      } else {
        promise = this.setLoginProfile({ profileId })
      }

      promise
        .then((data) => {
          let targetUrl = data?.targetUrl || '/'
          const context = data?.context
          // hide url params for security reason
          if (
            targetUrl.includes('/nav') &&
            context &&
            (context.referralId ||
              context.accountNumber ||
              context.masterId ||
              context.referralContext)
          ) {
            const contextKey = Date.now()
            targetUrl = targetUrl.split('?')[0]
            sessionStorage.setItem(contextKey, JSON.stringify(context))
            targetUrl += `?contextKey=${contextKey}`
          }
          window.location.href = targetUrl
        })
        .catch((error) => {
          console.error(error)
          this.isSubmitting = false
        })
    },
  },
}
</script>
<style lang="scss" scoped>
:deep() {
  .s-loading-wrap {
    > div {
      margin-top: 0 !important;
    }
  }
}
.selected {
  padding: 0 12px;
  cursor: not-allowed;
  font-style: italic;
  font-size: 14px;
  color: #4d627b;
}
</style>
