import { render, staticRenderFns } from "./global-search-modal.vue?vue&type=template&id=270076ca"
import script from "./global-search-modal.vue?vue&type=script&lang=js"
export * from "./global-search-modal.vue?vue&type=script&lang=js"
import style0 from "./global-search-modal.vue?vue&type=style&index=0&id=270076ca&prod&lang=scss&scope=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports