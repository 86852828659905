<script>
import { goTerms } from '../core/helper'
export default {
  created() {
    this.goHome()
  },
  methods: {
    goHome() {
      const { $store: store, $router: router } = this

      // If the user needs to agree to the terms
      if (goTerms(router, store)) {
        return
      }

      const defaultPage =
        store.state.userInfo?.roleConfiguration?.defaultPagePath ?? '/'
      router.push(defaultPage)
    },
  },
}
</script>
