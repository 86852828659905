const settingPages = [
  {
    path: '/settings',
    name: 'settings',
    meta: {
      title: 'Settings',
    },
    component: () =>
      import(
        /* webpackChunkName: "settings-page" */ '@/views/common/settings/index.vue'
      ),
  },
]

export default settingPages
