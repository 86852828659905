export default [
  {
    path: '/not-found',
    name: 'not-found',
    meta: {
      title: 'Not Found',
    },
    component: () =>
      import(
        /* webpackChunkName: "notFound" */ '../views/common/portalErrorPages/notFound.vue'
      ),
  },
  {
    path: '/not-permission',
    name: 'not-permission',
    meta: {
      title: 'Not Permission',
    },
    component: () =>
      import(
        /* webpackChunkName: "notPermission" */ '../views/common/portalErrorPages/notPermission.vue'
      ),
  },
  {
    path: '/log-out',
    name: 'log-out',
    meta: {
      title: 'Log Out',
    },
    component: () =>
      import(
        /* webpackChunkName: "logOut" */ '../views/common/portalErrorPages/logOut.vue'
      ),
  },
]
