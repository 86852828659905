var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-grow-1"},[(!_vm.$store.getters.isShowMobileMenu)?_c('ul',{ref:"navLinks",staticClass:"navbar-nav navbar-expand"},[_vm._l((_vm.headerMenu),function(menuItem,index){return [(menuItem.children)?_c('s-dropdown',{key:menuItem.title,scopedSlots:_vm._u([{key:"dropdown",fn:function(){return [_c('s-dropdown-menu',_vm._l((menuItem.children),function(childItem){return _c('s-dropdown-item',{key:childItem.label},[_c('router-link',{staticClass:"nav-link text-reset",class:[
                  { 'router-link-active': _vm.getHeaderIsActive(childItem) },
                ],attrs:{"to":_vm.getHeaderLink(childItem)}},[_vm._v(" "+_vm._s(childItem.label)+" ")])],1)}),1)]},proxy:true}],null,true)},[_c('div',{ref:"navLink",refInFor:true,staticClass:"nav-item nav-item-parent"},[(index < _vm.moreMenuIndex)?_c('span',{staticClass:"nav-link"},[_vm._v(" "+_vm._s(menuItem.label)+" "),_c('s-icon',{attrs:{"icon-name":"arrow-bottom"}})],1):_vm._e()])]):_c('div',{key:menuItem.label,ref:"navLink",refInFor:true,staticClass:"nav-item"},[(index < _vm.moreMenuIndex)?_c('router-link',{class:{
            'nav-link': true,
            'router-link-active': _vm.getHeaderIsActive(menuItem),
          },attrs:{"id":`nav_${_vm.$lodash.kebabCase(menuItem.label)}-link`,"to":_vm.getHeaderLink(menuItem)}},[_vm._v(" "+_vm._s(menuItem.label)+" ")]):_vm._e()],1)]}),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.moreMenuIndex < _vm.headerMenu.length),expression:"moreMenuIndex < headerMenu.length"}],ref:"moreNav",staticClass:"nav-item dropdown"},[_c('s-dropdown',{scopedSlots:_vm._u([{key:"dropdown",fn:function(){return [_c('s-dropdown-menu',{staticClass:"more-menu"},[_vm._l((_vm.headerMenu),function(menuItem,index){return [(index + 1 > _vm.moreMenuIndex)?_c('s-dropdown-item',{key:menuItem.label},[_c('router-link',{staticClass:"nav-link",class:[
                    { 'router-link-active': _vm.getHeaderIsActive(menuItem) },
                  ],attrs:{"id":`nav_${_vm.$lodash.kebabCase(menuItem.label)}-link`,"to":_vm.getHeaderLink(menuItem)}},[_vm._v(" "+_vm._s(menuItem.label)+" ")])],1):_vm._e()]})],2)]},proxy:true}],null,false,3777070827)},[_c('a',{staticClass:"nav-link dropdown-toggle",attrs:{"href":"javascript:void(0)","role":"button","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false","id":"nav_more-link"}},[_vm._v(" More "),_c('s-icon',{attrs:{"icon-name":"arrow-bottom"}})],1)])],1)],2):_vm._e(),(_vm.$store.getters.isShowMobileMenu && _vm.$store.state.mobileMenuStatus)?[_c('div',{staticClass:"nav-menu-mask"}),_c('transition',{attrs:{"name":"menu"}},[_c('div',{staticClass:"nav-menu"},[_c('s-list',{scopedSlots:_vm._u([{key:"list-item",fn:function(itemData){return [_c('router-link',{staticClass:"menu-link",attrs:{"to":_vm.getHeaderLink(itemData.item)}},[_c('div',{staticClass:"m-0 d-flex justify-content-between"},[_c('div',{class:{
                    'theme-text-blue':
                      _vm.getNavMenuStatus(itemData.item.path) ||
                      _vm.getHeaderIsActive(itemData.item),
                  }},[_vm._v(" "+_vm._s(itemData.item.label)+" ")]),(
                    _vm.getNavMenuStatus(itemData.item.path) ||
                    _vm.getHeaderIsActive(itemData.item)
                  )?_c('s-icon',{staticClass:"check-menu",attrs:{"icon-name":"agree","width":"22","height":"22"}}):_vm._e()],1)])]}}],null,false,1991390550),model:{value:(_vm.headerMenu),callback:function ($$v) {_vm.headerMenu=$$v},expression:"headerMenu"}})],1)])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }