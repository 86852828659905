export default () => {
  return Object.assign({
    websiteCode: '',
    frontendConfig: {},
    httpHeaders: {
      'X-REQUEST-WITH': '',
      'X-XSRF-TOKEN': '',
    },
    userInfo: {},
    whitelistUrl: [],
    roleCategory: {
      advisor: ['advisor'],
      officer: ['officer'],
      client: ['client'],
    },
    toggleSideBarStatus: false,
    menuHandCollapseSwitch: false,
    sidebarData: [],
    fullScreenLoading: false,
    IS_SHOW_MFA: false,
    IS_SHOW_GLOBAL_SEARCH: false,
    SEARCH_HISTORY: JSON.parse(localStorage.getItem('SEARCH_HISTORY')) || [],
    MFA_PHONE_NUMBER:
      '' || JSON.parse(sessionStorage.getItem('MFA_PHONE_NUMBER')),
    codePermissions: {},
    sidebarExpand: true,
    isMobile: /Mobi|Android|iPhone/i.test(navigator.userAgent),
    mobileMenuStatus: false,
    twoHundredsTimesZoom: false,
    logoutBecauseOfSingleDeviceRule: false,
    needSaveIpToServer: true,
  })
}
