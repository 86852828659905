/**
 * @method Download file with url.
 * @description The file will appear at the bottom of the window and the download progress will be displayed
 * @param {string} url
 * @param {string} [filename]
 * @returns
 */
function downloadWithUrl(url, filename) {
  if (!url) {
    return
  }
  try {
    const downloadLink = document.createElement('a')
    downloadLink.style.display = 'none'
    downloadLink.href = url
    // not work for change file name
    if (filename) {
      downloadLink.setAttribute('download', filename)
    }
    if (typeof downloadLink.download === 'undefined') {
      downloadLink.setAttribute('target', '_blank')
    }
    document.body.appendChild(downloadLink)
    downloadLink.click()

    setTimeout(function () {
      document.body.removeChild(downloadLink)
    }, 200)
  } catch (_) {}
}

/**
 * @method Download file with data or url
 * @description It won't appear at the bottom of the window until the file is downloaded
 * @param {string | ArrayBuffer | ArrayBufferView | Blob} data file data string or url
 * @param {string} filename
 * @param {string} [mime]
 * @param {string | Uint8Array} [bom]
 */
function downloadWithData(data, filename, mime, bom) {
  const blobData = typeof bom !== 'undefined' ? [bom, data] : [data]
  const blob = new Blob(blobData, { type: mime || 'application/octet-stream' })
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, filename)
  } else {
    const blobURL =
      window.URL && window.URL.createObjectURL
        ? window.URL.createObjectURL(blob)
        : window.webkitURL.createObjectURL(blob)
    const tempLink = document.createElement('a')
    tempLink.style.display = 'none'
    tempLink.href = blobURL
    tempLink.setAttribute('download', filename)

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank')
    }

    document.body.appendChild(tempLink)
    tempLink.click()

    // Fixes "webkit blob resource error 1"
    setTimeout(function () {
      document.body.removeChild(tempLink)
      window.URL.revokeObjectURL(blobURL)
    }, 200)
  }
}

/**
 * @deprecated
 * @method Preview file with data or url
 * @description It won't appear at the bottom of the window until the file is downloaded
 * @param {string | ArrayBuffer | ArrayBufferView | Blob} data file data string or url
 * @param {string} filename
 * @param {string} [mime]
 * @param {string | Uint8Array} [bom]
 */
function previewWithData(data, filename, mime, bom) {
  const blobData = typeof bom !== 'undefined' ? [bom, data] : [data]
  const blob = new Blob(blobData, {
    type: mime || 'application/pdf;charset=utf-8',
  })
  if (typeof window.navigator?.msSaveOrOpenBlob !== 'undefined') {
    window.navigator.msSaveOrOpenBlob(blob, filename)
  } else {
    const blobURL =
      window.URL && window.URL.createObjectURL
        ? window.URL.createObjectURL(blob)
        : window.webkitURL.createObjectURL(blob)
    window.open(blobURL)
    // window.URL.revokeObjectURL(blobURL)
  }
}

export { downloadWithUrl, downloadWithData, previewWithData }
