(function (d) {
  const styleElement = d.createElement('STYLE')
  const domEvents = 'addEventListener' in d
  const addEventListener = function (type, callback) {
    // Basic cross-browser event handling
    if (domEvents) {
      d.addEventListener(type, callback)
    } else {
      d.attachEvent('on' + type, callback)
    }
  }
  let setCss = function (cssText) {
    // Handle setting of <style> element contents in IE8
    if (styleElement.styleSheet) {
      styleElement.styleSheet.cssText = cssText
    } else {
      styleElement.innerHTML = cssText
    }
  }

  d.getElementsByTagName('HEAD')[0].appendChild(styleElement)

  // Using mousedown instead of mouseover, so that previously focused elements don't lose focus ring on mouse move
  addEventListener('mousedown', function () {
    setCss(':focus{outline:0}::-moz-focus-inner{border:0;}')
  })

  addEventListener('keydown', function () {
    setCss('')
  })
})(document)
