<template>
  <s-container :fluid="fluid" class="footer-wrap bound-client-rect">
    <s-row>
      <s-col>
        <div
          ref="footerContent"
          class="portal-footer-content"
          v-html="sanitizeFooterContent"
        ></div>
      </s-col>
    </s-row>
  </s-container>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'main',
    },
    fluid: {
      type: Boolean,
      default: true,
    },
    height: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    sanitizeFooterContent: {
      handler() {
        this.$nextTick(() => {
          this.emitFooterHeight()
        })
      },
      immediate: true,
    },
  },
  computed: {
    sanitizeFooterContent() {
      let content =
        this.$store.state.frontendConfig?.settings?.footerCode?.[this.type] ||
        ''
      content = this.setCurrentYear(content)
      content = this.setStaticResourcePath(content)
      content = this.setRoleDisplay(content)
      return this.$utils.sanitizeHtml(content)
    },
  },
  methods: {
    // emit footer height
    emitFooterHeight() {
      this.$emit('update:height', this.$refs.footerContent.offsetHeight)
    },
    setCurrentYear(content) {
      return content.replace(
        /\<\%\s*currentYear\s*\%\>/g,
        new Date().getFullYear()
      )
    },
    setStaticResourcePath(content) {
      return content.replace(
        /\<\%\s*staticResourcePath\s*\%\>/g,
        this.$store.state.frontendConfig.cloudFrontUrl + '/static-resources/'
      )
    },
    setRoleDisplay(content) {
      const roleDisplay = content.match(
        /<%(\s*)if(\s*)\(([^%>]+)\)(\s*){(\s*)%>([\s\S]*)<%(\s*)}(\s*)%>/g
      )
      if (roleDisplay && roleDisplay.length > 0) {
        const roles = roleDisplay[0]
          .match(/<%\s*if\s*\(([^%>]+)\)\s*{\s*%>/)[1]
          .split('|')
        if (roles && roles.indexOf(this.$store.getters.role) > -1) {
          const roleDisplayDom = roleDisplay[0].match(
            /<%\s*if\s*\([^%>]+\)\s*{\s*%>([\s\S]*)<%\s*}\s*%>/
          )[1]
          return content.replace(
            /<%(\s*)if(\s*)\(([^%>]+)\)(\s*){(\s*)%>([\s\S]*)<%(\s*)}(\s*)%>/g,
            roleDisplayDom
          )
        } else {
          return content.replace(
            /<%(\s*)if(\s*)\(([^%>]+)\)(\s*){(\s*)%>([\s\S]*)<%(\s*)}(\s*)%>/g,
            ''
          )
        }
      } else {
        return content
      }
    },
  },
}
</script>

<style lang="scss" scoped>
// for accessbility
.portal-footer-content {
  overflow: hidden;
  :deep(a) {
    text-decoration: underline;
  }
}
</style>
