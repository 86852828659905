<template>
  <header class="header-navbar" ref="header-navbar">
    <nav
      class="navbar navbar-bg-color navbar-theme"
      :class="{ 'd-block': showMobileMenu }"
    >
      <div
        :class="{ 'navbar-brand': true, 'navbar-brand-mobile': showMobileMenu }"
      >
        <router-link class="navbar-brand-content" :to="defaultPage">
          <img
            id="navbar-logo"
            class="navbar-logo-img"
            ref="logoImg"
            :src="settings.images.reverseLogo"
            :alt="logoAlt"
          />
        </router-link>
        <div class="navbar-brand-profile" v-if="showMobileMenu">
          <nav-profile ref="profile"></nav-profile>
        </div>
      </div>
      <div class="collapse navbar-collapse">
        <nav-link ref="headerNav"></nav-link>
        <nav-profile ref="profile" v-if="!showMobileMenu"></nav-profile>
      </div>
      <div v-if="showRefresh">
        <s-button
          icon="loading"
          button-type="outline-primary"
          icon-only
          size="sm"
          @click.native="$emit('refresh')"
        ></s-button>
      </div>
    </nav>
  </header>
</template>

<script>
import NavLink from './NavLink.vue'
import NavProfile from './NavProfile.vue'

export default {
  components: {
    NavLink,
    NavProfile,
  },
  data() {
    return {
      showRefresh: process.env.VUE_APP_SHOW_REFRESH === 'true',
      settings: this.$store.state.frontendConfig.settings,
    }
  },
  computed: {
    logoAlt() {
      if (this.settings.i18n && this.settings.i18n.website_logo_alt) {
        return this.settings.i18n.website_logo_alt
      }
      return this.settings.companyTitle || 'logo'
    },
    defaultPage() {
      const state = this.$store.state
      return state.userInfo?.roleConfiguration?.defaultPagePath ?? '/'
    },
    showMobileMenu() {
      this.$nextTick(() => {
        this.emitHeaderNavbarHeight()
      })
      return this.$store.getters.isShowMobileMenu
    },
  },
  mounted() {
    // Set menu width adaptive
    this.$refs.logoImg.onload = () => {
      this.$refs.headerNav.handleMenuWidthOut()
      this.emitHeaderNavbarHeight()
    }
    this.$refs.logoImg.onerror = () => {
      this.$refs.headerNav.handleMenuWidthOut()
      document.querySelector('.navbar-logo-img').style.width = '158px'
      this.emitHeaderNavbarHeight()
    }
    // get top menu height

    this.emitHeaderNavbarHeight()
    window.onresize = this.$lodash.throttle(() => {
      this.$refs.headerNav.handleMenuWidthOut()
      this.emitHeaderNavbarHeight()
    }, 160)
  },
  methods: {
    emitHeaderNavbarHeight() {
      this.$emit(
        'getHeaderNavbarHeight',
        this.$refs['header-navbar'].clientHeight
      )
    },
  },
}
</script>
<style lang="scss" scoped>
.header-navbar {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: $zindex-fixed;

  .navbar {
    position: relative;
    align-items: center;
    justify-content: space-between;
    padding: 0px 16px;
    min-height: 56px;
    @include media-breakpoint-up(lg) {
      // Fix IE 11 bug
      &:after {
        content: '';
        min-height: inherit;
        font-size: 0;
      }
    }
  }

  .navbar-logo-img {
    max-height: 56px;
    width: auto;
    font-size: 14px;
    overflow: hidden;
  }

  .navbar-bg-color {
    background-color: $theme-blue;
  }

  .navbar-brand {
    margin-right: 16px;
    padding: 5px 0;
    display: flex;
    justify-content: center;
    .navbar-brand-profile {
      display: flex;
      align-items: center;
    }
    .navbar-brand-content {
      display: flex;
      align-items: center;
      color: $white;
      line-height: 56px;

      &:focus {
        outline: auto;
      }
    }
  }
  .navbar-brand-mobile {
    display: flex;
    justify-content: space-between;
  }
  .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
  }

  @include media-breakpoint-up(md) {
    .navbar {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
      }
    }
  }

  @include media-breakpoint-down(md) {
    .navbar {
      flex-direction: column;
      // padding-bottom: 8px;

      .navbar-brand {
        margin: 0;
        padding: 16px 2px;
      }

      .navbar-collapse {
        flex-direction: column;
      }

      .navbar-right {
        justify-content: space-between;
        width: 100%;
      }
    }
  }
}
</style>
